import { ADMIN, DOCTOR, PERMISSION_EDIT } from 'src/constants/users'

export function getUser (state) {
  return state.user
}

export function getCashBookForm (state) {
  let form = {
    ...state.user.doctor.cash_book_form,
    doctor: {
      ctps: state.user.doctor.ctps
    }
  }

  if (form.work_at_home) {
    form = {
      ...form,
      address: state.user.doctor.address
    }
  }

  return form
}

export function getAvatar (state) {
  return state.user?.avatar_url ?? null
}

export function isAuth (state) {
  return !!state.user && state.user.is_active === true
}

export function isDoctor (state) {
  return isAuth(state) && state.user.type === DOCTOR
}

export function isAdmin (state) {
  return isAuth(state) && state.user.type === ADMIN
}

export function isApp (state) {
  return isDoctor(state)
}

export function isDashboard (state) {
  return isAdmin(state)
}

export function isMaster (state) {
  return isAuth(state) && state.user.is_master === true
}

export function hasRole (state) {
  return (role, permission = PERMISSION_EDIT) => {
    return isMaster(state) || !!(
      isAdmin(state) &&
      (state.user.permissions ?? []).find(_role => _role.name === role && _role[permission])
    )
  }
}

export function hasTwoFactor (state) {
  return isAuth(state) && state.user.has_two_factor === true
}

export function hasFlag (state) {
  return (flag) => {
    return (state.user?.flags ?? []).some(_flag => _flag.description === flag)
  }
}
